<template>
  <div class="candidate-dashboard mb-3">
    <div
       v-if="
        (currentUserRole == Role.customerAdmin ||
        currentUserRole == Role.customerRecruiter) && !isEducationalInstitute
      "
    >  
      <CRow class="tile">
        <CCol style="white-space: nowrap;" 
          v-for="({ id, label, value }, index) in getCandidateData"
          :key="index"
        >
          <div class="card">
            <div class="card-body d-flex align-items-center p-1 maintainwidth"> 
              <div>
                <div class="text-value" :style="getTheme(id).text_color">
                <div
                class="p-2 icon-wrapper"
                :style="getTheme(id).text_color"
              >
                <i v-if="iconHide(id)" :class="getTheme(id).src" />
                <img v-else :src="getTheme(id).src" />
                 <span class="text-value align-left">{{ value }}</span>
              </div> 
                </div>
                <div class="text-muted font-weight-bold small pl-2">{{ label }}</div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>       
    </div>
    <div v-else-if="isEducationalInstitute">  
      <CRow class="tile">
        <CCol v-for="({ id, label, value }, index) in getCandidateData" :key="index" class="col-12 col-sm-6 col-md-3 col-lg-2">
          <div class="card">
            <div class="p-1" style="max-width: 600px;"> 
              <div>
                <div class="text-value" :style="getTheme(id).text_color">
                  <div class="p-2 icon-wrapper" :style="getTheme(id).text_color">
                    <i v-if="iconHide(id)" :class="getTheme(id).src" />
                    <img v-else :src="getTheme(id).src" />
                    <span class="text-value align-left">{{ value }}</span>
                  </div> 
                </div>
                <div class="text-muted font-weight-bold small pl-2 d-flex">{{ label }}</div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
    <div class="tile" v-else>
      <Tile
        :jobTotalCount="getJobTotalCount"
        :shortlistCount="getDashboardMetrics.SHORTLIST"
        :interviewCount="getDashboardMetrics.INTERVIEW"
        :recruitCount="getTotalCount"
      />
    </div>
    <CRow v-if="!isEducationalInstitute">
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <CRow class="card-header-title d-flex align-items-center pl-3">
              <h5 class="text-primary">Job Sub Status</h5>
            </CRow>
          </CCardHeader>
          <CCardBody class="d-flex justify-content-center align-items-center">
            <CRow>
              <CCol lg="5" id="leftChart">
                  <CustomPieChart
                    class="col-12"
                    :PieChartData="getTabTotalCounts"
                    :PieChartLegendData="getTabTotalCounts"
                    :isFromJobStatus="true"
                  />
              </CCol>
            <CCol lg="7" id="rightChart">
                <CustomPieChart
                  class="col-12"
                  :PieChartData="getTabStatusTotalCounts"
                  :PieChartLegendData="getTabStatusTotalCounts"
                  :isFromJobStatus="true"
                />
              </CCol>
            </CRow>
          </CCardBody>
          <div id="jcp"></div>
        </CCard>
      </CCol> 
    </CRow>
     <CRow v-if="!isEducationalInstitute">
      <CCol>
        <CandidateJobAlert :shortlistCandidates="getshortlistcandidates" />
      </CCol>
    </CRow>
    <div>
      <Transactions/>
    </div>
    <CRow>
      <CCol lg="6" sm="12" v-if="isEducationalInstitute" style="">
        <CCard class="p-4">
          <Bar :data="chartData" :height="384" graphTitle="Students"/>
        </CCard>
      </CCol>
      <CCol lg="6" sm="12" class="d-flex flex-column">
        <ScratchPad />
      </CCol>
      <CCol lg="6" sm="12" v-if="!isEducationalInstitute">
        <CalendarPad :events="interviewEvents" />
      </CCol>
    </CRow>
    <!-- <CRow>
      <CCol>
        <RAGAlert />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CandidateCommunication />
      </CCol>
    </CRow> -->
  </div>
</template>
<script>
import Tile from "@/containers/Dashboard/RecruiterDashBoard/Tile";
import ScratchPad from "@/containers/Dashboard/RecruiterDashBoard/ScratchPad";
import CalendarPad from "@/containers/Dashboard/RecruiterDashBoard/CalendarPad";
import CandidateJobAlert from "@/containers/Dashboard/RecruiterDashBoard/Alert1-CandidateJobStatus";
import Transactions from "@/containers/Dashboard/RecruiterDashBoard/Transactions";
import RAGAlert from "@/containers/Dashboard/RecruiterDashBoard/Alert2-RAG";
import CandidateCommunication from "@/containers/Dashboard/RecruiterDashBoard/CandidateCommunication";
import { FACILITY_CANDIDATE_URL } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope, CONSTANT as CONST } from "@/helpers/helper";
import CustomPieChart from "@/components/Dashboard/CustomPieChart";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import m from "moment";
import Bar from "@/components/reusable/Charts/Bar.vue"

export default {
  components: {
    Tile,
    ScratchPad,
    CalendarPad,
    CandidateJobAlert,
    RAGAlert,
    CandidateCommunication,
    CustomPieChart,
    Transactions,
    Bar,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getJobTotalCount",
      "getinterviewlistcandidates",
      "getTotalCount",
      "getDashboardMetrics",
      "getShortliststatus",
      "getInterviewstatus",
      "getRecruitstatus",
      "getFacilityConfig",
      "getshortlistcandidates",
      "getCandidateExpiryCount",
      "getScratchPads",
      "getJobCandidateTabs",
      "getTabTotalCounts",
      "getTabStatusTotalCounts",
      "getUser",
      "getCandidateByTypeId",
      "getInterviewSlots",
      "getCustomerTypeIDFromAccessToken",
      "isSuperCustomer",
      "getOrgTypeIDFromAccessToken",
      "getCandidateCourseCount",
      "getOrgID",
    ]),
    getCandidateData() {
      let getCandidateData =
        this.getCandidateByTypeId &&
        this.getCandidateByTypeId.candidate_stats &&
        this.getCandidateByTypeId.candidate_stats.summary &&
        this.getCandidateByTypeId.candidate_stats.summary.map((data) => {
          const getData = {};
          getData.id = data.candidate_type_id;
          getData.label = data.candidate_type;

          getData.value = data.num_candidates;

          return getData;
        });
      if(!this.isEducationalInstitute) {
        var totalJob = {
          id: 8,
          label: "Total Jobs Posted",
          value: this.getJobTotalCount,
        };
      }
      else if (this.isEducationalInstitute) {
        var totalCourses = {
          id: 10,
          label: "Total Courses",
          value: this.getCandidateCourseCount?.courses_count,
        };
        var fully_qualified = {
          id: 11,
          label: "Total Fully Qualified",
          value: this.getCandidateCourseCount.fully_qualified_count,
        };
        var student_count = {
          id: 12,
          label: "Total Students",
          value: this.getCandidateCourseCount.student_count,
        };
        var sponsored_candidates_count = {
          id: 13,
          label: "Sponsored Candidates",
          value: this.getCandidateCourseCount.sponsored_candidates_count,
        };
        // Static values
        var cpd_courses = {
          id: 14,
          label: "CPD Courses",
          value: 5,
        };
      }

      let totalCandidates = {
        id: 9,
        label: "Total Candidates",
        value: this.getTotalCount,
      };
      if (!this.isEducationalInstitute) {
        getCandidateData?.unshift(totalJob, totalCandidates);
      } else {
        getCandidateData?.unshift(totalCandidates) || [];
        getCandidateData?.push(totalCourses, fully_qualified, student_count, sponsored_candidates_count, cpd_courses) || [];
      }
      // let agency = { id: 0, label: "Agency", value: "--" };
      // getCandidateData ? getCandidateData.push(agency) : [];
      return getCandidateData;
    },
    interviewEvents() {
      const t = this.getInterviewSlots.filter(v=>v?.selected_slot_date && v?.selected_slot_start && v?.selected_slot_end).map((v) => {
        let start = new Date(v?.selected_slot_date);
        const startTimeSplit = v?.selected_slot_start.split(":");
        start.setHours(startTimeSplit[0], startTimeSplit[1], startTimeSplit[2]);

        let end = new Date(v?.selected_slot_date);
        const endTimeSplit = v?.selected_slot_end.split(":");
        end.setHours(endTimeSplit[0], endTimeSplit[1], endTimeSplit[2]);

        const title = v?.candidate?.full_name
          ? `Interview - ${v?.candidate?.full_name} | ${v?.job_display_uid} - ${v?.job?.job_title}`
          : "--";
        return {
          start: this.formatDate(start, "YYYY-MM-DD HH:mm"),
          end: this.formatDate(end, "YYYY-MM-DD HH:mm"),
          title,
          content: `Interviewer: ${v?.assigned_to_users_name}`,
          class: "leisure",
        };
      });
      return t;
    },
    isEducationalInstitute() {
      return this.getCustomerTypeIDFromAccessToken ===  CONST.CUSTOMER_TYPES.educational_institute || false;
    },
    chartData() {
      const privateCandidateValue = this.getCandidateCourseCount?.private_candidate_count;
      const nonPrivateCandidateValue = this.getCandidateCourseCount?.not_private_candidate_count;

      return {
        // xAxisLabel: "Candidates",
        cdata: [
          { label: "Private Registrations", value: privateCandidateValue, backgroundColor: "#ff8c1a", borderColor: "#e67300" },
          { label: "Public Registrations", value: nonPrivateCandidateValue, backgroundColor: "#00ffcc", borderColor: "#00cccc" },
        ],
        barThickness: 50,
      };
    },
  }, 
  methods: {
    ...mapActions([
      "fetchJobTotalCount",
      "getTotalCandidates",
      "getallshortliststatus",
      "getScratchPadItems",
      "fetchJobCandidateTabs",
      "jobCandidateTabTotalCount",
      "fetchUser",
      "fetchCandidateByTypeId",
      "fetchInterviewSlot",
      "scrollTop",
      "fetchCourseCount"
    ]),
    routeToCandidateList() {
      this.$router.push(FACILITY_CANDIDATE_URL);
    },
    getTheme(id) {
      let src = "";
      let color = "";
      switch (id) {
        case 0:
          src = "fas fa-clinic-medical";
          color = "#35c792;";
          break;
        case 1:
          src = "fas fa-user-md";
          color = "#8a08a2;";
          break;
        case 2:
          src = "fas fa-user-nurse";
          color = "#1973e8";
          break;
        case 3:
          src = "fas fa-notes-medical";
          color = "#61892F;";
          break;
        case 4:
          src = "fas fa-tooth";
          color = "#e01f66;";
          break;
        case 5:
          src = "fas fa-prescription-bottle-alt";
          color = "#B23850;";
          break;
        case 6:
          src = "fas fa-hospital";
          color = "#895061;";
          break;
        case 8:
          src = "img/job.png";
          color = "#8a08a2;";
          break;
        case 9:
          src = "img/group.png";
          color = "#dd3651;";
          break;
        case 10:
          src = "fa-solid fa-chalkboard-user";
          color = "#35c792;";
          break;
        case 11:
          src = "fa-solid fa-user-group";
          color = "#8a08a2;";
          break;
        case 12:
          src = "fa-solid fa-graduation-cap";
          color = "#61892F;";
          break;
        default:
          src = "fas fa-user-shield";
          color = "#9117a8;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        color: "color: " + color,
        text_color: "color: " + color,
      };
    },
    iconHide(id) {
      if (id == 8 || id == 9) return false;
      return true;
    },
    formatDate(date, format) {
      return m(date).format(format) || "--";
    },
  },
  created() {
    this.scrollTop()
    let appendAction = [];
    let appendConfigAction;
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      if(this.isEducationalInstitute) {
        const payload = {
          customer_uid: this.getCustomerId,
          organisation_id: this.getOrgID
        }
        this.fetchCourseCount(payload)
      }
      else {
        this.fetchJobTotalCount(this.getCustomerId);
        this.jobCandidateTabTotalCount(this.getCustomerId);
      }
      this.getTotalCandidates(this.getCustomerId);
      this.fetchCandidateByTypeId();
      this.fetchInterviewSlot();
    });
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 450px) {
  .tile {
    margin-top: 20px;
  }
}

.maintainwidth{
  min-width: 158px;
}
.text-value{ 

  width: max-content;
	img, i {
    vertical-align: middle;
    border-style: none;
    float: left;
    height: 27px;
    font-size: 27px;
    margin-top: 6px;
    margin-right: 16px;
	} 
}
 
</style>
